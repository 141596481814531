<template>
  <v-card flat>
    <v-row class="ma-2" justify="space-between">
      <div class="mt-1">
        <v-btn elevation="3" @click="back()">
          <v-icon :left="$vuetify.breakpoint.smAndUp" color="primary" large>mdi-arrow-left-circle</v-icon>
          <div class="hidden-xs-only ml-2 font-weight-bold">Назад</div>
        </v-btn>
      </div>
      <div class="mt-1">
        <v-dialog v-model="cashlessDialog" width="400px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="order.payment_system === 'NONE' && role ==='ADMIN'" elevation="3" v-bind="attrs" v-on="on">
              <v-icon :left="$vuetify.breakpoint.smAndUp" color="warning" large>mdi-credit-card</v-icon>
              <div class="hidden-xs-only ml-2 font-weight-bold">Перевести в без. нал.</div>
            </v-btn>
          </template>
          <v-card class="pa-3" shaped>
            <v-toolbar color="primary" dark dense>
              <v-btn class="mr-1 row justify-end" icon>
                <v-icon large @click="cashlessDialog = false">mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-row class="ma-3">
              <v-col cols="12">
                <v-autocomplete v-model="order.payment_system"
                                :items="payment_system"
                                dense
                                hide-details
                                label="выберите систему оплаты"
                                outlined
                                rounded/>
              </v-col>
            </v-row>
            <v-row class="justify-center pb-3">
              <v-btn color="primary" elevation="3" outlined rounded @click="makeCashless()">
                Перевести
              </v-btn>
            </v-row>
          </v-card>
        </v-dialog>
        <v-btn v-if="order.driver && (role ==='ADMIN' || role === 'MONITOR')" class="ml-3" elevation="3" @click="finish()">
          <v-icon :left="$vuetify.breakpoint.smAndUp" color="warning" large>mdi-clock-fast</v-icon>
          <div class="hidden-xs-only ml-2 font-weight-bold">Завершить</div>
        </v-btn>
      </div>
    </v-row>
    <v-dialog v-model="descDialog" max-width="400" transition="slide-x-transition">
      <v-card class="pa-3" shaped>
        <v-toolbar color="primary" dark dense elevation="5">
          <v-btn class="mr-1 row justify-end" icon>
            <v-icon large @click="descDialog = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="justify-space-around" no-gutters>
          <v-col cols="12">
            <div class="pa-4">
              <v-textarea v-model="client.description"
                          dense
                          hide-details
                          label="Введите заметку"
                          outlined
                          rounded/>
            </div>
            <v-row class="justify-center" no-gutters>
              <v-btn color="primary" elevation="3" outlined ripple rounded @click="desc">
                <v-icon left>mdi-check</v-icon>
                <span class="font-weight-bold">OK</span>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancelDialog" max-width="400" transition="slide-x-transition">
      <v-card class="pa-3" shaped>
        <v-toolbar color="primary" dark dense elevation="5">
          <v-btn class="mr-1 row justify-end" icon>
            <v-icon large @click="cancelDialog = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="justify-space-around" no-gutters>
          <v-col cols="12">
            <div class="pa-4">
              <v-textarea v-model="cancel_reason"
                          dense
                          hide-details
                          label="Введите причину"
                          outlined
                          rounded/>
            </div>
            <v-row class="justify-center" no-gutters>
              <v-btn v-if="cancel_reason" color="primary" elevation="5" outlined rounded @click="cancel">
                <v-icon left>mdi-check</v-icon>
                <span class="font-weight-bold">OK</span>
              </v-btn>
              <v-btn v-else color="grey darken-3" disabled elevation="5" outlined rounded @click="cancel"></v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="menuDialog" max-width="750" transition="slide-x-transition">
      <v-data-table :headers="headers"
                    :items="storeItems"
                    :items-per-page="20"
                    :search="search"
                    class="elevation-1">
        <template v-slot:top>
          <v-row class="mx-4 pt-4" no-gutters>
            <v-text-field v-model="search"
                          dense
                          hide-details
                          outlined/>
          </v-row>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon class="ml-4" color="primary" small @click="addItem(item)">mdi-plus</v-icon>
        </template>
        <template v-slot:item.price="{ item }">
          <span>{{ item.price }} с.</span>
        </template>
        <template v-slot:item.image="{ item }">
          <v-row align="center" justify="center">
            <v-avatar class="grey lighten-2" size="25">
              <img v-if="item.image" :src="item.image" alt=""/>
              <img v-else alt="" src="/item_placeholder.png"/>
            </v-avatar>
          </v-row>
        </template>
      </v-data-table>
    </v-dialog>
    <v-row class="mx-1 mt-3" no-gutters>
      <v-col class="col col-lg-6 col-md-6 col-sm-6 row no-gutters justify-start d-flex align-center" cols="12">
        <v-tooltip v-if="order.partner" top>
          <template v-slot:activator="{ attrs, on }">
            <v-btn :class="$vuetify.breakpoint.xs ? 'text-body-2' : 'text-h6'" color="blue lighten-3" depressed small v-bind="attrs"
                   v-on="on">№ {{ order.number }}
            </v-btn>
          </template>
          <span>от Партнера</span>
        </v-tooltip>
        <v-btn v-else :class="$vuetify.breakpoint.xs ? 'text-body-2' : 'text-h6'" small text>№ {{
            order.number
          }}
        </v-btn>
      </v-col>
      <v-col :class="$vuetify.breakpoint.xs ? 'text-body-2' : 'text-h6'"
             class="col col-lg-6 col-md-6 col-sm-6 row no-gutters justify-lg-end justify-md-end justify-sm-end d-flex align-center"
             cols="12">
        <v-icon v-if="order.payment_system === 'NONE'" :class="$vuetify.breakpoint.xs ? 'mx-3' : 'mr-2'"
                color="primary">mdi-cash
        </v-icon>
        <v-icon v-else-if="order.payment_system === 'PAYBOX'" :class="$vuetify.breakpoint.xs ? 'mx-3' : 'mr-2'"
                color="orange">mdi-credit-card
        </v-icon>
        <v-icon v-else-if="order.payment_system === 'ODENGI'" :class="$vuetify.breakpoint.xs ? 'mx-3' : 'mr-2'"
                color="orange">mdi-alpha-o-circle-outline
        </v-icon>
        <v-icon v-else-if="order.payment_system === 'BALANCE'" :class="$vuetify.breakpoint.xs ? 'mx-3' : 'mr-2'"
                color="orange">mdi-alpha-b-circle-outline
        </v-icon>
        <span>Итого :</span>
        <div v-if="order.partner" class="ml-1" style="cursor: pointer;" @click="storePriceDialog = true">
          <v-hover v-slot="{ hover }">
            <span :class="{ 'on-hover': hover }">{{ order.store_price }}</span>
          </v-hover>
        </div>
        <div v-else class="ml-1">{{ order.store_price }} c.</div>
        <div v-if="order.discount > 0" class="ml-1">- {{ order.discount }} c.</div>
        <div v-if="order.store_delivery_price > 0" class="ml-1">+ {{ order.store_delivery_price }} c.(заведение)</div>
        <div class="ml-1">
          +
          <v-hover v-slot="{ hover }">
            <span :class="{ 'on-hover': hover }" style="cursor: pointer"
                  @click="deliveryPriceDialog = true">{{ order.delivery_price - order.store_delivery_price }}</span>
          </v-hover>
          c.(клиент)
        </div>
        <div class="ml-1">= {{ order.total_price - order.discount }} c.</div>
      </v-col>
    </v-row>
    <v-row :class="$vuetify.breakpoint.xs ? 'mt-1' : ''" justify="space-around">
      <v-card :class="col" class="col-12" elevation="2">
        <v-img height="215" src="/user_background.jpeg">
          <v-app-bar color="rgba(0, 0, 0, 0)" flat>
            <v-row>
              <v-col v-if="client.description" class="row no-gutters justify-center d-flex align-center" cols="5">
                <span class="white--text text-body-2 mt-7 font-italic" @click="descDialog = true">
                  {{ client.description.slice(0, 60) }}
                </span>
              </v-col>
              <v-col v-else class="row no-gutters justify-center d-flex align-center" cols="5">
                <v-btn class="white--text mt-7" outlined rounded small @click="descDialog = true">
                  <span class="text-caption">Заметка +</span>
                </v-btn>
              </v-col>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-col class="row no-gutters justify-center d-flex align-center" cols="2">
                    <v-avatar class="row mt-7" size="56px">
                      <img alt=""
                           src="/user_avatar.png"
                           v-bind="attrs"
                           @click="historyDialog = true"
                           v-on="on"/>
                    </v-avatar>
                  </v-col>
                </template>
                <span>История клиента</span>
              </v-tooltip>
              <v-col class="d-flex align-center" cols="5">
                <div class="row no-gutters justify-space-around pt-8">
                  <span class="white--text text-body-2">Черный список</span>
                  <v-checkbox v-model="client.blocked"
                              class=""
                              color="indigo darken-1"
                              dark
                              hide-details
                              @change="toggle"/>
                </div>
              </v-col>
            </v-row>
          </v-app-bar>
          <v-row v-if="order.client" class="pt-4 white--text" no-gutters>
            <v-col class="row no-gutters justify-center" cols="12">
              <div>{{ order && order.client && order.client.name ? order.client.name : "Без имени" }}</div>
              <div v-if="order.confirm_reason" class="font-italic ml-3 caption d-flex align-center">
                ({{ order.confirm_reason }})
              </div>
            </v-col>
            <v-row justify="center" no-gutters>
              <div class="text-body-2">{{ order.client.phone | normalizePhone }}</div>
            </v-row>
            <v-col class="text-center text-body-2 py-1" cols="12">
              <span style="cursor: pointer" @click="modalOn">{{ order.address }}</span>
            </v-col>
            <v-col class="text-center text-body-2 py-1" cols="12">
              <span>{{ order.description }}</span>
            </v-col>
            <v-row v-if="!order.confirmed_at" class="justify-space-around">
              <v-chip color="white" link outlined>
                <span class="font-weight-bold" @click="confirm">Принять</span>
              </v-chip>
              <v-chip color="white" link outlined>
                <span class="font-weight-bold" @click="cancelDialog = true">Отменить</span>
              </v-chip>
            </v-row>
          </v-row>
        </v-img>
        <v-card-text>
          <div class="font-weight-bold row no-gutters justify-space-between">
            <v-icon class="ml-9">mdi-source-commit-start-next-local</v-icon>
            <v-btn fab small @click="editAddressComment">
              <v-icon v-if="showTitle">mdi-pencil</v-icon>
              <v-icon v-else>mdi-check</v-icon>
            </v-btn>
          </div>
          <v-timeline align-top class="pt-3" dense>
            <v-timeline-item color="blue" fill-dot icon="mdi-clock-outline" small>
              <div class="font-weight-normal">
                <strong>{{ timeFormat(order.started_at) }}</strong>
                <div v-if="showTitle" class="mt-8">
                  Заведение: {{ order.store_comment }}
                </div>
                <div v-else class="mt-8">
                  Заведение:
                  <v-text-field
                      v-model="order.store_comment"
                      clearable
                      dense
                      rounded
                      solo/>
                </div>
                <div v-if="showTitle" class="mt-8">
                  Курьер: {{ order.driver_comment }}
                </div>
                <div v-else class="mt-8">
                  Курьер:
                  <v-text-field
                      v-model="order.driver_comment"
                      clearable
                      dense
                      rounded
                      solo/>
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item
                class="pb-3"
                color="primary"
                fill-dot
                icon="mdi-check"
                small>
              <div class="font-weight-normal">
                <strong>{{ timeFormat(order.confirmed_at) }}</strong>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
      <v-card v-if="order.confirmed_at" :class="col" class="col-12" elevation="2">
        <v-img height="215" src="/store_background.jpeg">
          <v-app-bar color="rgba(0, 0, 0, 0)" flat>
            <v-avatar class="row justify-center mt-7" size="56">
              <img :src="order.store.logo" alt=""/>
            </v-avatar>
          </v-app-bar>
          <v-row v-if="order.store" class="pt-4 white--text" no-gutters>
            <v-col v-if="order && order.store && order.store.name" class="text-center py-1" cols="12">
              <span>{{ order.store.name }}</span>
            </v-col>
            <v-col class="text-center text-no-wrap text-body-2 py-1" cols="12">
              <span>{{ order.store.phone | normalizePhone }}</span>
            </v-col>
            <v-col class="text-center text-body-2 py-1" cols="12">
              <span>{{ order.store.address }}</span>
            </v-col>
            <v-row v-if="order.store_canceled_at" class="mt-1 justify-space-around">
              <v-chip color="white" link outlined>
                <span class="font-weight-bold" @click="restart">Повторить заказ</span>
              </v-chip>
              <v-chip color="white" link outlined>
                <span class="font-weight-bold" @click="cancelDialog = true">Отменить</span>
              </v-chip>
            </v-row>
            <v-row v-else-if="!order.store_started_at" class="mt-1 justify-space-around">
              <v-chip v-if="!order.canceled_at" color="white" link outlined>
                <span class="font-weight-bold" @click="find">Начать готовить</span>
              </v-chip>
              <v-chip color="white" link outlined>
                <span class="font-weight-bold" @click="cancelDialog = true">Отменить</span>
              </v-chip>
            </v-row>
          </v-row>
        </v-img>
        <v-card-text>
          <div class="font-weight-bold ml-9 mb-2">
            <v-icon>mdi-source-commit-start-next-local</v-icon>
          </div>
          <v-timeline align-top class="pt-3" dense>
            <v-timeline-item color="blue"
                             fill-dot
                             icon="mdi-clock-outline"
                             small>
              <div class="font-weight-normal">
                <strong>{{ timeFormat(order.store_started_at) }}</strong>
                <div class="mt-8"></div>
              </div>
            </v-timeline-item>
          </v-timeline>
          <v-timeline align-top class="pt-3" dense>
            <v-timeline-item class="pb-3"
                             color="primary"
                             fill-dot
                             icon="mdi-check"
                             small>
              <div>
                <div class="font-weight-normal">
                  <strong>{{ timeFormat(order.store_finished_at) }}</strong>
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
      <v-card v-if="order.store_started_at && !order.store_canceled_at" :class="col" class="col-12" elevation="2">
        <v-img height="215" src="/driver_background.jpeg">
          <v-app-bar color="rgba(0, 0, 0, 0)" flat>
            <v-avatar class="row justify-center mt-7" size="56">
              <img v-if="order.driver && order.driver.photo"
                   :src="order.driver.photo"
                   alt=""/>
              <img v-else alt="" src="/user_avatar.png"/>
            </v-avatar>
          </v-app-bar>
          <v-row class="white--text pt-4" no-gutters>
            <v-col v-if="order.driver && order.driver.name" class="text-center text-body-1 py-1" cols="12">
              <span>{{ order.driver.name }}</span>
            </v-col>
            <v-col v-if="order.driver" class="text-center text-body-2 py-1" cols="12">
              <span>{{ order.driver.phone | normalizePhone }}</span>
            </v-col>
            <v-row class="justify-space-around" no-gutters>
              <v-chip v-if="order.driver_confirmed_at" color="white" link outlined>
                <span class="font-weight-bold" @click="free">Освободить</span>
              </v-chip>
              <drivers-push v-else :id="order.id"></drivers-push>
              <v-chip color="white" link outlined>
                <span class="font-weight-bold" @click="cancelDialog = true">Отменить</span>
              </v-chip>
            </v-row>
          </v-row>
        </v-img>
        <v-card-text v-if="order.driver">
          <div class="font-weight-bold ml-9 mb-2">
            <v-icon>mdi-source-commit-start-next-local</v-icon>
          </div>
          <v-timeline align-top class="pt-3" dense>
            <v-timeline-item color="blue"
                             fill-dot
                             icon="mdi-clock-outline"
                             small>
              <div>
                <div class="font-weight-normal">
                  <strong>{{ timeFormat(order.driver_confirmed_at) }}</strong>
                  <div class="mt-8"></div>
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
          <v-timeline align-top class="pt-3" dense>
            <v-timeline-item class="pb-3"
                             color="primary"
                             fill-dot
                             icon="mdi-check"
                             small>
              <div class="font-weight-normal">
                <strong>{{ timeFormat(order.driver_started_at) }}</strong>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-card-text v-else class="px-0">
          <div v-if="notified.length > 0" class="row no-gutters justify-center">Уведомления отправлены:</div>
          <div v-else class="row no-gutters justify-center">Список пуст</div>
          <v-row v-for="driver in notified" :key="driver.id" class="grey lighten-4 mt-1" no-gutters>
            <v-col class="row no-gutters justify-center d-flex align-center" cols="6">
              <div>
                <div class="row no-gutters justify-center text-body-2">{{ driver.driver_name }}</div>
                <div v-if="driver.vehicle === 'Пешком'" class="row no-gutters justify-center caption">Пешком</div>
                <div v-if="driver.vehicle === 'Мото'" class="row no-gutters justify-center caption">Мото</div>
                <div v-if="driver.vehicle === 'Мопед'" class="row no-gutters justify-center caption">Мопед</div>
                <div v-else-if="driver.vehicle === 'Автомобиль'" class="row no-gutters justify-center caption">
                  Автомобиль
                </div>
              </div>
            </v-col>
            <v-col class="row no-gutters justify-center d-flex align-center" cols="3">
              <span class="caption">{{ driver.store_distance }}м. <br> {{ driver.client_distance }}м.</span>
            </v-col>
            <v-col class="row no-gutters justify-center d-flex align-center" cols="3">
              <v-chip color="primary" label small @click="assign(driver.driver_id)">
                <span class="caption">Назначить</span>
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="col-12">
        <v-toolbar color="deep-purple accent-4" dark>
          <v-toolbar-title v-if="order && order.store && order.store.name"
                           class="row justify-center font-weight-bold text-body-1">
            {{ order.store.name }}
          </v-toolbar-title>
          <v-btn v-if="isEditing" class="mr-4" fab light small @click="showItems">
            <v-icon large>mdi-plus</v-icon>
          </v-btn>
          <v-btn v-if="!order.driver_started_at && !order.store_finished_at" class="mr-0" fab light small
                 @click="editItems">
            <v-icon v-if="!isEditing">mdi-pencil</v-icon>
            <v-icon v-else>mdi-check-bold</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="mt-2 pr-7" dense>
          <v-col class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center" cols="1">
            <span class="caption font-weight-bold"></span>
          </v-col>
          <v-col class="col col-lg-3 col-md-3 col-sm-3 justify-lg-start justify-md-start justify-sm-start
          row no-gutters justify-center d-flex align-center" cols="5">
            <span class="caption font-weight-bold">Блюдо</span>
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.xsOnly" class="row no-gutters justify-start d-flex align-center" cols="4">
            <span class="caption font-weight-bold">Описание</span>
          </v-col>
          <v-col v-if="!isEditing" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end d-flex align-center"
                 cols="4">
            <span class="caption font-weight-bold">Кол-во</span>
          </v-col>
          <v-col v-else class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end d-flex align-center" cols="4">
            <span class="caption font-weight-bold pr-10">Кол-во</span>
          </v-col>
          <v-col class="row no-gutters justify-end d-flex align-center" cols="2">
            <span class="caption font-weight-bold">Сумма</span>
          </v-col>
        </v-row>
        <v-row v-for="item in items" :key="item.id" class="" no-gutters>
          <v-row v-if="item.count > 0" class="mt-2 pr-7" no-gutters>
            <v-col class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center" cols="2">
              <v-avatar size="36px">
                <img v-if="item.image" :src="item.image" alt=""/>
                <img v-else alt="" src="/item_placeholder.png"/>
              </v-avatar>
            </v-col>
            <v-col class="col col-lg-3 col-md-3 col-sm-3 row no-gutters justify-start d-flex align-center" cols="5">
              <span v-if="item.name" class="text-body-2">{{ item.name }}</span>
            </v-col>
            <v-col v-if="!$vuetify.breakpoint.xsOnly" class="row no-gutters justify-start d-flex align-center" cols="3">
              <span v-if="item.description" class="text-body-2">{{ item.description }}</span>
            </v-col>
            <v-col v-if="!isEditing" class="row no-gutters justify-end d-flex align-center" cols="3">
              <span class="pr-4">{{ item.count }}</span>
            </v-col>
            <v-col v-else class="justify-lg-end justify-md-end justify-sm-end row no-gutters justify-space-between d-flex align-center"
                   cols="3">
              <v-btn fab x-small @click="decreaseItem(item.item_id)">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-row class="col-3 justify-center">{{ item.count }}</v-row>
              <v-btn fab x-small @click="increaseItem(item.item_id)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end d-flex align-center" cols="2">
              <span v-if="item.discount > 0" :class="$vuetify.breakpoint.xs ? '' : 'pr-4'"
                    class="text-decoration-line-through font-italic">
                {{ item.price }} c.
              </span>
              <span class="text-no-wrap">{{ item.price - item.discount }} c.</span>
            </v-col>
          </v-row>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-row class="mt-3 pr-7 font-weight-bold" no-gutters>
          <v-col class="row no-gutters justify-center d-flex align-center" cols="2">
            <span :class="$vuetify.breakpoint.xs ? 'pl-3' : 'pl-11'">Итого</span>
          </v-col>
          <v-col class="row no-gutters justify-end d-flex align-center" cols="10">
            <span>{{ sum }} c.</span>
            <span v-if="order.store_delivery_price && order.store_delivery_price > 0">+ {{ order.store_delivery_price }} c.(заведение)</span>
            <span>+ {{ order.delivery_price - order.store_delivery_price }} c.(клиент)</span>
            <span>= {{ sum + order.delivery_price }} c.</span>
          </v-col>
        </v-row>
        <v-row v-if="order.payment_system === 'PAYBOX'" class="mt-2 pr-8" no-gutters>
          <v-col class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center" cols="2">
            <v-icon color="orange" large>mdi-credit-card</v-icon>
          </v-col>
          <v-col class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-start d-flex align-center" cols="6">
            <span>{{ order.payment_system }} ({{ order.paybox_system }})</span>
          </v-col>
          <v-col class="row no-gutters justify-end d-flex align-center" cols="4">
            <span class="font-weight-bold">{{ order.paybox_id }}</span>
          </v-col>
        </v-row>
        <v-row v-else-if="order.payment_system === 'ODENGI'" class="pr-8 mt-2" no-gutters>
          <v-col class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center" cols="2">
            <v-icon color="orange" large>mdi-alpha-o-circle-outline</v-icon>
          </v-col>
          <v-col class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-start d-flex align-center" cols="6">
            <span>{{ order.payment_system }}</span>
          </v-col>
          <v-col class="row no-gutters justify-end d-flex align-center" cols="4">
            <span class="font-weight-bold">{{ order.paybox_id }}</span>
          </v-col>
        </v-row>
        <v-row v-else-if="order.payment_system === 'BALANCE'" class="pr-8 mt-2" no-gutters>
          <v-col class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center" cols="2">
            <v-icon color="orange" large>mdi-alpha-b-circle-outline</v-icon>
          </v-col>
          <v-col class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-start d-flex align-center" cols="6">
            <span>{{ order.payment_system }}</span>
          </v-col>
          <v-col class="row no-gutters justify-end d-flex align-center" cols="4">
            <span class="font-weight-bold">{{ order.paybox_id }}</span>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
    <v-dialog v-model="historyDialog" :max-width="$vuetify.breakpoint.xs ? 374 : 750">
      <history :order="order"></history>
    </v-dialog>
    <v-dialog v-model="storePriceDialog" :max-width="$vuetify.breakpoint.xs ? 374 : 374">
      <v-card class="pa-3" shaped>
        <v-toolbar color="primary" dark dense elevation="5">
          <v-btn class="mr-1 row justify-end" icon>
            <v-icon large @click="storePriceDialog = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="ma-3">
          <v-col cols="12">
            <v-text-field v-model="order.store_price"
                          dense
                          hide-details
                          label="Цена от заведения"
                          outlined
                          rounded
                          type="number"/>
          </v-col>
        </v-row>
        <v-row class="justify-space-around pb-3" no-gutters>
          <v-btn color="error" elevation="3" outlined rounded @click="storePriceDialog = false">Отменить</v-btn>
          <v-btn color="primary" elevation="3" outlined rounded @click="changeStorePrice">Сохранить</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deliveryPriceDialog" :max-width="$vuetify.breakpoint.xs ? 374 : 374">
      <v-card class="pa-3" shaped>
        <v-toolbar color="primary" dark dense elevation="5">
          <v-btn class="mr-1 row justify-end" icon>
            <v-icon large @click="deliveryPriceDialog = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="ma-3">
          <v-col cols="12">
            <v-text-field v-model="order.delivery_price"
                          dense
                          hide-details
                          label="Цена доставки"
                          outlined
                          rounded
                          type="number"/>
          </v-col>
        </v-row>
        <v-row class="justify-space-around pb-3" no-gutters>
          <v-btn color="error" elevation="3" outlined rounded @click="deliveryPriceDialog = false">Отменить</v-btn>
          <v-btn color="primary" elevation="3" outlined rounded @click="changeDeliveryPrice">Сохранить</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addressDialog" :max-width="$vuetify.breakpoint.xs ? 850 : 1250">
      <v-lazy>
        <div class="map">
          <l-map ref="orderMap" :center="[position.lat, position.lng]" :zoom="zoom" @dblclick="onMapClick">
            <l-geosearch :options="geoSearchOptions"></l-geosearch>
            <l-tile-layer :attribution="attribution" :url="url"/>
            <l-control class="example-custom-control">
              <v-btn class="" outlined small @click="changeAddress">Выбрать</v-btn>
            </l-control>
            <l-marker :draggable="true"
                      :lat-lng.sync="position"
                      visible
                      @dragend="dragging = false"
                      @dragstart="dragging = true">
              <l-tooltip :content="tooltipContent" :options="{ permanent: true }"></l-tooltip>
            </l-marker>
          </l-map>
        </div>
      </v-lazy>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "@/services/axios";
import history from "@/components/history";
import format from "date-fns/format";
import driversPush from "./driversPush";
import {LControl, LMap, LMarker, LTileLayer, LTooltip} from "vue2-leaflet";
import {OpenStreetMapProvider} from "leaflet-geosearch";
import LGeosearch from "vue2-leaflet-geosearch";

export default {
  name: 'index-active',
  components: {
    driversPush,
    history,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LGeosearch,
    LControl
  },
  data() {
    return {
      deliveryPriceDialog: false,
      storePriceDialog: false,
      address: "",
      position: {},
      geoSearchOptions: {
        provider: new OpenStreetMapProvider({
          params: {
            countrycodes: "kg",
          }
        }),
        showMarker: false,
        autoClose: true
      },
      loading: false,
      dragging: false,
      zoom: 18,
      url: 'https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=a4b4d72cdb1c433e803753b11ab6ccb6',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      addressDialog: false,
      payment_system: [
        'PAYBOX',
        'ODENGI',
        'BALANCE'
      ],
      cashlessDialog: false,
      isEditingNote: false,
      cancel_reason: null,
      itemsEdited: false,
      cancelDialog: false,
      menuDialog: false,
      showTitle: true,
      isEditing: false,
      historyDialog: false,
      search: "",
      headers: [
        {
          text: "Изображение",
          value: "image",
          class: "caption primary--text",
        },
        {
          text: "Наименование",
          value: "name",
          class: "caption primary--text",
        },
        {
          text: "Цена",
          value: "price",
          class: "caption primary--text",
        },
        {
          text: "Добавить",
          value: "action",
          sortable: false,
          class: "caption primary--text",
        },
      ],
      descDialog: false,
      client: {
        description: "",
        blocked: false,
      },
    }
  },
  created() {
    this.fetch(this.$route.params.id);
  },
  watch: {
    position: {
      deep: true,
      async handler() {
        this.address = await this.getAddress();
      }
    }
  },
  computed: {
    tooltipContent() {
      if (this.dragging) return "...";
      if (this.loading) return "Loading...";
      return `<strong>${this.address}</strong> <hr/><strong>lat:</strong> ${this.position.lat}<br/> <strong>lng:</strong> ${this.position.lng}`;
    },
    role() {
      return this.$store.getters["auth/role"];
    },
    sum() {
      let total = 0;
      for (let i = 0; i < this.items.length; i++) {
        total = total + this.items[i].price - this.items[i].discount;
      }
      return total;
    },
    col() {
      if (!this.order.confirmed_at) {
        return "col col-12 col-lg-12 col-md-12 col-sm-12";
      }
      if (this.order.confirmed_at && (!this.order.store_started_at || this.order.store_canceled_at)) {
        return "col col-6 col-lg-6 col-md-6 col-sm-6";
      }
      return "col col-4 col-lg-4 col-md-4 col-sm-4";
    },
    items() {
      return this.$store.getters["order/items"];
    },
    storeItems() {
      return this.$store.getters["order/storeItems"];
    },
    notified() {
      return this.$store.getters["order/notified"];
    },
    order() {
      return this.$store.getters["order/order"];
    },
  },
  methods: {
    changeDeliveryPrice() {
      this.$confirm(
          "Изменить цену ?",
          "Внимание",
          "question"
      ).then(() => {
        console.log('delivery price changed');
        this.deliveryPriceDialog = false;

        axios.send({
          url: "/orders/delivery",
          method: "POST",
          data: {id: this.order.id, price: parseInt(this.order.delivery_price)},
        }).then(() => {
          this.$router.go();
        })
      })
    },
    changeStorePrice() {
      this.$confirm(
          "Изменить цену ?",
          "Внимание",
          "question"
      ).then(() => {
        console.log('store price changed');
        this.storePriceDialog = false;

        axios.send({
          url: "/orders/store",
          method: "POST",
          data: {id: this.order.id, price: parseInt(this.order.store_price)},
        }).then(() => {
          this.$router.go();
        })
      })
    },
    modalOn() {
      this.addressDialog = true;
      this.getIIS();
      this.ref();
    },
    ref() {
      setTimeout(() => {
        this.$refs.orderMap.mapObject.on("geosearch/showlocation", this.onSearch);
      }, 2000)
    },
    changeAddress() {
      axios.send({
        url: "/orders",
        method: "POST",
        data: {id: this.order.id, lat: this.position.lat, lng: this.position.lng, address: String(this.address)},
      }).then(() => {
        this.$router.go();
      })
    },
    async getAddress() {
      this.loading = true;
      let address = "Unresolved address";
      try {
        const {lat, lng} = this.position;
        const result = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
        );
        if (result.status === 200) {
          const body = await result.json();
          let ad = body.display_name;
          let adr = ad.split(",")
          address = adr.slice(0, 2);
        }
      } catch (e) {
        console.error("Reverse Geocode Error->", e);
      }
      this.loading = false;
      return address;
    },
    async onMapClick(value) {
      this.position = value.latlng;
    },
    onSearch(value) {
      const loc = value.location;
      this.position = {lat: loc.y, lng: loc.x};
    },
    getIIS() {
      if (this.order.lat && this.order.lng) {
        this.position = {lat: this.order.lat, lng: this.order.lng};
      }
    },
    assign(id) {
      axios.send({
        url: "/orders/driver/" + this.order.id + "/" + id,
        method: "POST",
      }).then(() => {
        this.$router.go();
      });
    },
    searchNotified() {
      if (this.order.id) {
        this.$store.dispatch("order/fetchNotifiedDrivers", this.order.id);
      }
    },
    toggle() {
      if (this.client.blocked) {
        axios.send({
          url: "/clients/" + this.client.id,
          method: "PUT",
        }).then((resp) => {
          this.order.client = resp.data;
        });
      } else {
        axios.send({
          url: "/clients/" + this.client.id,
          method: "DELETE",
        }).then((resp) => {
          this.order.client = resp.data;
        });
      }
    },
    desc() {
      this.order.client.description = this.client.description;
      axios.send({
        url: "/clients",
        method: "POST",
        data: this.order.client,
      }).then((resp) => {
        this.order.client = resp.data;
        this.descDialog = false;
      });
    },
    fetch(id) {
      this.$store.dispatch("order/fetch", id).then(() => {
        if (this.order && this.order.client) {
          this.client = this.order.client;
        }
        this.fetchItems();
        this.searchNotified();
      })
    },
    fetchItems() {
      if (this.order.id) {
        this.$store.dispatch("order/fetchItems", this.order.id);
      }
    },
    fetchStoreItems() {
      if (this.order.id) {
        this.$store.dispatch("order/fetchStoreItems", this.order.id);
      }
    },
    cancel() {
      axios.send({
        url: "/orders",
        method: "DELETE",
        data: {id: this.order.id, comment: this.cancel_reason},
      }).then(() => {
        this.$router.push({name: "orders"});
      });
    },
    finish() {
      this.$confirm(
          "Завершить заказ?",
          "Внимание",
          "question"
      ).then(() => {
        axios.send({
          url: "/orders/finish/" + this.order.id,
          method: "POST",
        }).then(() => {
          this.$router.push({name: "orders"});
        });
      })
    },
    makeCashless() {
      axios.send({
        url: "/orders/cashless",
        method: "POST",
        data: {id: this.order.id, payment_system: this.order.payment_system}
      }).then((resp) => {
        this.order = resp.data;
        this.cancelDialog = false;
        this.$router.go();
      });
    },
    confirm() {
      axios.send({
        url: "/orders/" + this.order.id,
        method: "POST",
      }).then(() => {
        this.$router.go();
      });
    },
    find() {
      axios.send({
        url: "/orders/" + this.order.id,
        method: "PUT",
      }).then(() => {
        this.$router.go();
      });
    },
    restart() {
      axios.send({
        url: "/orders/restart/" + this.order.id,
        method: "PUT",
      }).then(() => {
        this.$router.go();
      });
    },
    free() {
      axios.send({
        url: "/orders/driver/" + this.order.id,
        method: "DELETE",
      }).then(() => {
        this.$router.go();
      });
    },
    editAddressComment() {
      this.showTitle = !this.showTitle;
      if (this.showTitle) {
        axios.send({
          url: "/orders/address",
          method: "POST",
          data: this.order,
        }).then(() => {
          this.$router.go();
        });
      }
    },
    decreaseItem(id) {
      this.itemsEdited = true;
      let item = this.items.find((e) => {
        return e.item_id === id;
      });

      if (item.count > 0) {
        let price = item.price / item.count;
        let discount = item.discount / item.count;
        item.price = item.price - price;
        item.discount = item.discount - discount;
        item.count -= 1;

        if (item.children && item.children.length > 0) {
          item.children.forEach((e) => {
            for (let j = 0; j < e.count; j++) {
              this.decreaseItem(e.id);
            }
          });
        }
      }
    },
    increaseItem(id) {
      this.itemsEdited = true;
      let item = this.items.find((e) => {
        return e.item_id === id;
      });
      let price = item.price / item.count;
      let discount = item.discount / item.count;
      item.count++;
      item.price = item.price + price;
      item.discount = item.discount + discount;
      if (item.children && item.children.length > 0) {
        item.children.forEach((e) => {
          for (let j = 0; j < e.count; j++) {
            this.increaseItem(e.id);
          }
        });
      }
    },
    editItems() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        let items = this.items.filter((el) => {
          return el.count > 0;
        })
        axios.send({
          url: "/orders/items/" + this.order.id,
          method: "POST",
          data: {list: items},
        }).then(() => {
          this.$router.go();
        });
      }
    },
    addItem(item) {
      this.menuDialog = false;
      this.items.push(item);
      if (item.children && item.children.length > 0) {
        item.children.forEach((i) => {
          let item = this.items.find((e) => {
            return e.item_id === i.id;
          });
          if (item) {
            this.increaseItem(i.id);
          } else {
            this.items.push(i);
          }
        });
      }
    },
    showItems() {
      this.menuDialog = true;
      this.fetchStoreItems();
    },
    back() {
      this.$router.push({name: "orders"});
    },
    timeFormat(d) {
      if (d) {
        return format(new Date(d), "HH:mm");
      }
      return "";
    },
  },
  filters: {
    normalizePhone: (p) => {
      if (!p) {
        return "";
      }
      let result = "";
      let phones = p.split(",");

      phones.forEach((phone) => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "");
        let temp = "+";
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " ";
          }
          temp = temp + phone[i];
        }
        result = result + temp + ",";
      });

      return result.substr(0, result.length - 1);
    },
  },
};
</script>

<style scoped>
.map {
  height: 850px;
  border: 2px solid black;
}

.example-custom-control {
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 0.1em;
}

.on-hover {
  color: green;
  font-weight: bold;

}
</style>
