<template>
  <v-card shaped class="pa-3">
    <v-toolbar shaped v-if="finished.length > 0" elevation="5" class="row no-gutters justify-center mb-4" dense color="primary">
      <v-toolbar-title>Успешные заказы</v-toolbar-title>
    </v-toolbar>
    <v-row no-gutters class="my-1 lime lighten-4 justify-center" v-for="o in finished" :key="o.id">
      <v-col cols="3" class="col col-lg-2 col-md-2 col-sm-2 py-0 justify-center d-flex align-center">
        <v-avatar>
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <v-avatar v-bind="attrs" v-on="on">
                <img :src="o.store.logo" :alt="o.store.name">
              </v-avatar>
            </template>
            <span>{{ o.store.name }}</span>
            <v-spacer></v-spacer>
            <span>{{ o.store.address }}</span>
          </v-tooltip>
        </v-avatar>
      </v-col>
      <v-col cols="9" class="col col-lg-5 col-md-5 col-sm-5 py-0 justify-start d-flex align-center">
        <div class="text-body-2">
          <v-btn color="lime lighten-3" depressed class="font-weight-bold" @click="routeFinished(o.id)">№
            {{ o.number }}
          </v-btn>
          <div class="text-center">{{ o.address }}</div>
        </div>
      </v-col>
      <v-col cols="6" class="col col-lg-2 col-md-2 col-sm-2 py-0 justify-end d-flex align-center">
        <div>
          <v-chip small label color="blue" text-color="white" class="mb-1">
            <v-icon left>mdi-clock-time-three-outline</v-icon>
            <div>{{ timeFormat(o.started_at) }}</div>
          </v-chip>
          <v-chip small label color="primary" text-color="white">
            <v-icon left>mdi-check-all</v-icon>
            <div>{{ timeFormat(o.finished_at) }}</div>
          </v-chip>
        </div>
      </v-col>
      <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 py-0 justify-end d-flex align-center">
        <v-icon large class="mr-2" v-if="!o.paybox_id" color="primary">mdi-cash</v-icon>
        <v-icon large class="mr-2" v-else color="orange">mdi-credit-card</v-icon>
      </v-col>
      <v-col cols="4" class="col col-lg-2 col-md-2 col-sm-2 py-0 justify-center d-flex align-center body-2">
        <div>
          <div class="ml-2" v-if="o.store_price">{{ o.store_price }} c.</div>
          <div v-if="o.discount > 0">- {{ o.discount }} c.</div>
          <div>+ {{ o.delivery_price }} c.</div>
          <div>= {{ o.total_price - o.discount }} c.</div>
        </div>
      </v-col>
    </v-row>
    <v-divider v-if="canceled.length > 0 && finished.length > 0" class="my-4"></v-divider>
    <v-toolbar shaped v-if="canceled.length > 0" elevation="5" dense color="grey lighten-2" class="row no-gutters justify-center mb-4">
      <v-toolbar-title>Отмененные заказы</v-toolbar-title>
    </v-toolbar>
    <v-row no-gutters class="my-1 grey lighten-2 justify-center" v-for="o in canceled" :key="o.id">
      <v-col cols="3" class="col col-lg-2 col-md-2 col-sm-2 py-0 justify-center d-flex align-center">
        <v-avatar>
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <v-avatar v-bind="attrs" v-on="on">
                <img :src="o.store.logo" :alt="o.store.name">
              </v-avatar>
            </template>
            <span>{{ o.store.name }}</span>
            <v-spacer></v-spacer>
            <span>{{ o.store.address }}</span>
          </v-tooltip>
        </v-avatar>
      </v-col>
      <v-col cols="9" class="col col-lg-5 col-md-5 col-sm-5 py-0 justify-start d-flex align-center">
        <div class="text-body-2">
          <v-btn color="grey lighten-1" depressed class="font-weight-bold" @click="routeCanceled(o.id)">
            № {{ o.number }}
          </v-btn>
          <div class="text-center">{{ o.address }}</div>
        </div>
      </v-col>
      <v-col cols="6" class="col col-lg-2 col-md-2 col-sm-2 py-0 justify-end d-flex align-center">
        <div>
          <v-chip small label color="blue" text-color="white" class="mb-1">
            <v-icon left>mdi-clock-time-three-outline</v-icon>
            <div>{{ timeFormat(o.started_at) }}</div>
          </v-chip>
          <v-chip small label color="red" text-color="white">
            <v-icon left>mdi-cancel</v-icon>
            <div>{{ timeFormat(o.canceled_at) }}</div>
          </v-chip>
        </div>
      </v-col>
      <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 py-0 justify-end d-flex align-center">
        <v-icon large class="mr-2" v-if="!o.paybox_id" color="primary">mdi-cash</v-icon>
        <v-icon large class="mr-2" v-else color="orange">mdi-credit-card</v-icon>
      </v-col>
      <v-col cols="4" class="col col-lg-2 col-md-2 col-sm-2 py-0 justify-center d-flex align-center body-2">
        <div>
          <div class="ml-2" v-if="o.store_price">{{ o.store_price }} c.</div>
          <div v-if="o.discount > 0">- {{ o.discount }} c.</div>
          <div>+ {{ o.delivery_price }} c.</div>
          <div>= {{ o.total_price - o.discount }} c.</div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from "@/services/axios";
import format from "date-fns/format";

export default {
  props: {
    order: {
      type: Object,
    }
  },
  watch: {
    order: function (newVal, oldVal) {
      if (newVal.id !== oldVal.id) {
        this.items = [];
        this.fetch();

      }
    }
  },
  data() {
    return {
      items: []
    }
  },
  mounted() {
    this.fetch();
  },
  computed: {
    finished() {
      return this.items.filter(o => o.finished_at)
    },
    canceled() {
      return this.items.filter(o => o.canceled_at)
    }
  },
  methods: {
    fetch() {
      axios.send({
        url: "/orders/client/" + this.order.client.phone,
        method: "GET",
      }).then(resp => {
        this.items = resp.data;
      });
    },
    routeFinished(id) {
      this.$router.push({name: 'finished_order', params: {id: id}})
    },
    routeCanceled(id) {
      this.$router.push({name: 'canceled_order', params: {id: id}})
    },
    timeFormat(d) {
      if (d) {
        return format(new Date(d), "HH:mm -- dd-MM-yy")
      }
      return ''
    },
  }
}
</script>
