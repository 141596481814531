<template>
  <v-dialog v-model="dialog" :max-width="$vuetify.breakpoint.xs ? 374 : 500">
    <template v-slot:activator="{ on, attrs }">
      <v-chip v-bind="attrs" v-on="on" color="white" outlined text @click.stop="search">
        <div class="font-weight-bold">Отправить</div>
      </v-chip>
    </template>
    <v-card shaped class="pa-3">
      <v-toolbar dense color="primary" class="row no-gutters justify-center">
        <v-toolbar-title>Отправить уведомления</v-toolbar-title>
      </v-toolbar>
      <v-row class="green lighten-5 mb-1 mx-auto" v-for="driver in list" :key="driver.driver_id">
        <v-col cols="6" class="col col-lg-5 col-md-5 col-sm-5 justify-center d-flex align-center">
          <div>
            <div class="row no-gutters justify-center text-body-2">{{ driver.driver_name }}</div>
            <div class="row no-gutters justify-center caption">{{ driver.driver_phone | normalizePhone }}</div>
            <div v-if="driver.vehicle === 'Пешком'" class="row no-gutters justify-center caption">Пешком</div>
            <div v-if="driver.vehicle === 'Велосипед'" class="row no-gutters justify-center caption">Велосипед</div>
            <div v-if="driver.vehicle === 'Мото'" class="row no-gutters justify-center caption">Мото</div>
            <div v-if="driver.vehicle === 'Мопед'" class="row no-gutters justify-center caption">Мопед</div>
            <div v-else-if="driver.vehicle === 'Автомобиль'" class="row no-gutters justify-center caption">Автомобиль</div>
          </div>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center d-flex align-center">
          <span class="caption">{{ driver.count }}/{{ driver.total_count }}</span>
        </v-col>
        <v-col cols="5" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-center d-flex align-center">
          <span class="caption">{{ driver.store_distance }} м./{{ driver.client_distance }} м.</span>
        </v-col>
        <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
          <v-tooltip top>
            <template v-slot:activator="{ on,attrs }">
              <v-chip v-bind="attrs" v-on="on" small label color="primary">
                <v-icon dark @click.stop="send(driver.driver_id)">mdi-bell-ring</v-icon>
              </v-chip>
            </template>
            <span>Отправить</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "@/services/axios";

export default {
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      dialog: false,
      list: []
    }
  },
  methods: {
    search() {
      axios.send({
        url: "/orders/driver/" + this.id,
        method: "GET",
      }).then((resp) => {
        this.list = resp.data;
      });
    },
    send(id) {
      axios.send({
        url: "/orders/driver/" + this.id + "/" + id,
        method: "PUT",
      }).then(() => {
        this.$router.go();
      });
    }
  },
  filters: {
    normalizePhone: (p) => {
      if (!p) {
        return ""
      }
      let result = "";
      let phones = p.split(",");

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "")
        let temp = "+"
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " "
          }
          temp = temp + phone[i]
        }
        result = result + temp + ","
      })

      return result.substr(0, result.length - 1)
    },
  },
}
</script>
